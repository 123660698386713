<template>
  <FormInputValidation :name="name" :rules="rules" v-slot="{ errors }">
    <FormLabel v-if="label || $slots.label">
      {{ label }}
      <slot name="label"></slot>
    </FormLabel>

    <div class="flex items-center relative">
      <slot name="prefix">
        <span v-if="prefix" class="mr-1">{{ prefix }}</span>
      </slot>

      <IMaskComponent v-if="mask" v-on="compEventListeners" v-bind="compAttributes" v-model="compValue" />

      <textarea
        v-else-if="textArea"
        ref="textAreaElement"
        v-on="compEventListeners"
        v-bind="compAttributes"
        v-model="compValue"
      />

      <input v-else v-on="compEventListeners" v-bind="compAttributes" v-model="compValue" />

      <FontAwesomeIcon
        v-if="isLoading && iconPrefix"
        :icon="faSpinnerThird"
        spin
        fixedWidth
        class="icon-prefix icon-loading"
      />
      <div v-else-if="$slots.iconPrefix" class="icon-prefix"><slot name="iconPrefix" /></div>
      <FontAwesomeIcon v-else-if="iconPrefix" :icon="iconPrefix" fixedWidth class="icon-prefix" />

      <FontAwesomeIcon v-if="isLoading && iconSuffix" :icon="faSpinnerThird" spin fixedWidth class="icon-suffix" />
      <div v-else-if="$slots.iconSuffix" class="icon-suffix"><slot name="iconSuffix" /></div>
      <FontAwesomeIcon v-else-if="iconSuffix" :icon="iconSuffix" fixedWidth class="icon-suffix" />

      <span v-if="suffix" class="ml-1">{{ suffix }}</span>

      <slot name="actions"></slot>
    </div>

    <FormInputHint :error="showValidationError ? upperFirst(errors[0]) : undefined" :hint="compHint">
      <template slot="hint">
        <slot name="hint"></slot>
      </template>
    </FormInputHint>
  </FormInputValidation>
</template>

<script>
import { faEye, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import { throttle, upperFirst } from 'lodash'
import { IMaskComponent } from 'vue-imask'
import FormInputValidation from '@/components/forms/elements/FormInputValidation'
import FormInputHint from '@/components/forms/elements/FormInputHint'
import FormLabel from '@/components/forms/elements/FormLabel'

export default {
  name: 'FormText',
  components: {
    FormInputValidation,
    FormInputHint,
    FormLabel,
    IMaskComponent
  },
  props: {
    name: {
      type: String,
      required: true
    },
    value: [String, Number, Array],
    label: String,
    rules: [String, Object],
    placeholder: String,
    hint: String,
    autofocus: Boolean,
    small: Boolean,
    autoGrow: Boolean,
    autoGrowMaxHeight: {
      type: Number,
      default: 300
    },
    type: {
      type: String,
      default: 'text'
    },
    inputmode: {
      type: String,
      default: 'text'
    },
    textArea: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
      default: 3
    },
    mask: {
      type: Object,
      default: () => {}
    },
    unmaskValue: {
      type: [Boolean, String],
      default: true
    },
    prefix: [String, Boolean],
    suffix: String,
    iconPrefix: Object,
    iconSuffix: Object,
    isLoading: Boolean,
    showValidationError: {
      type: Boolean,
      default: true
    },
    targetClass: {
      type: String,
      default: ''
    },
    readonly: Boolean,
    disabled: Boolean,
    autocomplete: String,
    autocapitalize: String,
    autocorrect: String,
    spellcheck: String,
    monospace: Boolean,
    blockRecording: Boolean
  },
  data() {
    return {
      upperFirst,
      faEye,
      faSpinnerThird
    }
  },
  computed: {
    compValue: {
      set(newVal) {
        this.$emit('input', newVal)
      },
      get() {
        return this.value ? this.value.toString() : ''
      }
    },

    compHint() {
      return this.mask && this.mask.maskHint ? this.mask.maskHint : this.hint
    },

    // Since we don't want to duplicate attributes on both <input> and <imask> let's define them here
    // We will combine with any existing attributes we manually put on the 2 components
    compAttributes() {
      let inputBaseClass = this.small ? 'form-input form-input-small' : 'form-input'

      if (this.textArea) {
        inputBaseClass += ' form-input-text-area'
      }

      if (this.monospace) {
        inputBaseClass += ' font-mono'
      }

      if (this.disabled) {
        inputBaseClass += ' disabled'
      }

      // Stop session recorder from seeing text
      if (this.blockRecording) {
        inputBaseClass += ' lo_sensitive'
      } else {
        inputBaseClass += ' LoNotSensitive'
      }

      let attributes = {
        // iMask attributes. We can apply them to the <input> too as they won't have an effect
        mask: this.mask ? this.mask.maskPattern : undefined,
        unmask: this.unmaskValue ? this.unmaskValue : undefined,
        lazy: this.mask ? this.mask.lazy : undefined,
        overwrite: this.mask ? this.mask.overwrite : undefined,
        autofix: this.mask ? this.mask.autofix : undefined,
        blocks: this.mask ? this.mask.blocks : undefined,
        pattern: this.mask ? this.mask.pattern : undefined,
        format: this.mask ? this.mask.format : undefined,
        parse: this.mask ? this.mask.parse : undefined,

        // All other attributes, taken from props
        class: this.targetClass ? `${inputBaseClass} ${this.targetClass}` : inputBaseClass,
        placeholder: this.placeholder,
        type: this.type,
        inputmode: this.inputmode !== 'text' ? this.inputmode : undefined,
        name: this.name,
        readonly: this.readonly,
        autocomplete: this.autocomplete,
        autocapitalize: this.autocapitalize,
        autocorrect: this.autocorrect,
        spellcheck: this.spellcheck,
        rows: this.textArea ? this.rows : undefined,

        autofocus: this.autofocus
      }

      if (this.iconPrefix) {
        attributes.class += ' with-icon-prefix'
      }

      if (this.iconSuffix || this.type === 'password') {
        attributes.class += ' with-icon-suffix w-full'
      }

      return attributes
    },

    compEventListeners() {
      return {
        change: () => this.$emit('change'),
        click: event => this.scrollIntoView(event),
        keydown: event => this.onKeyDown(event),
        keyup: event => this.$emit('keyup', event),
        // Our masked input emits just the value of the input, whereas a normal input returns whole input event object
        input: event => {
          this.resizeTextArea(event)

          typeof event.target === 'object' ? this.$emit('input', event.target.value) : this.$emit('input', event)
        }
      }
    }
  },
  methods: {
    scrollIntoView(event) {
      // On long forms the native keyboard overlaps the fields :(
      const timeout = this.appIs.native ? 500 : 1
      setTimeout(() => this.$scroll.intoViewIfNeeded(event.target), timeout)
    },

    onKeyDown(event) {
      if (this.$listeners.captureEnterKeyPress && event.key === 'Enter') {
        event.preventDefault()
        event.stopPropagation()
        this.$emit('captureEnterKeyPress')
      }
    },

    resetTextAreaHeight() {
      if (this.$refs.textAreaElement) {
        this.$refs.textAreaElement.style.height = 'inherit'
      }
    },

    resizeTextArea: throttle(
      function (event) {
        if (!this.textArea || !this.autoGrow) {
          return
        }

        let field = event.target

        // Reset field height
        field.style.height = 'inherit'

        if (field.value === '' || !field.value) {
          this.$emit('resizeTextArea')
          return
        }

        // Get the computed styles for the element
        let computed = window.getComputedStyle(field)

        // Calculate the height
        let height =
          parseInt(computed.getPropertyValue('border-top-width'), 10) +
          parseInt(computed.getPropertyValue('padding-top'), 10) +
          field.scrollHeight +
          parseInt(computed.getPropertyValue('padding-bottom'), 10) +
          parseInt(computed.getPropertyValue('border-bottom-width'), 10)

        if (height > this.autoGrowMaxHeight) {
          height = this.autoGrowMaxHeight
        }

        field.style.height = height + 'px'

        this.$emit('resizeTextArea')
      },
      1000,
      { leading: true }
    )
  }
}
</script>

<style lang="scss" scoped>
.icon-prefix {
  @apply text-gray-darker;
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.icon-suffix {
  @apply text-gray-darker;
  position: absolute;
  right: 1rem;
  top: 1.2rem;
  z-index: 2;
}

.icon-loading {
  top: auto;
  transform: none;
}
</style>
